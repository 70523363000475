import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tree, Tooltip, Typography, Icons } from '~/components';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import { TreeTransparente, LinkArquivo } from './styles';
import { iconEnum } from '~/modules/Helper/iconEnum';
import { ChooseIconFile } from '~/modules/Helper/chooseIconFile';
import RemoveDiacritic from '../../../hooks/removeDiacritic';

const TreeViewModuloFixo = forwardRef(
  ({ treeView, visao, setSearchValue }, ref) => {
    const { TreeNode } = Tree;
    const { corTema } = useSelector(state => state.configuration);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [treeData, setTreeData] = useState(treeView);

    useEffect(() => {
      setSearchValue('');
      setTreeData(treeView);
    }, [treeView]);

    const loop = data =>
      data.map(item => {
        if (
          (item.children && item.children.length) ||
          item.PrimeiroNivel
        ) {
          return (
            <TreeNode
              key={item.key}
              title={
                item.PrimeiroNivel ?
                  (<>
                    <Typography.Text
                      style={{
                        display: 'block',
                        width: '100%',
                        outline: 0,
                        cursor: 'default',
                        marginRight: -15,
                      }}
                    >
                      {item.title}
                    </Typography.Text>
                  </>)
                  :
                  (<>
                    <Typography.Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '0.5rem',
                        outline: 0,
                        cursor: 'default',
                      }}
                    >
                      <span>
                        {item.title}
                      </span>
                      {item.ExibeDetalhes && (
                        <Tooltip title="Informações" placement="top">
                          <Link to={`${visao}/${item.key}`} style={{ display: 'flex', cursor: 'pointer' }}>
                            <Icons.InfoCircleOutlined style={{ fontSize: '18px', color: corTema }} />
                          </Link>
                        </Tooltip>
                      )}
                    </Typography.Text>
                  </>)
              }
            >
              {loop(item.children)}
            </TreeNode>
          );
        }
        const fileExtension = item?.Link?.split('.', 2)[1].toLowerCase();
        if (item.Externo) {
          return (
            <TreeNode
              key={item.key}
              isLeaf
              switcherIcon={
                fileExtension &&
                iconEnum
                  .find(
                    x => x.name === `file-${ChooseIconFile(fileExtension)}`
                  )
                  ?.value({ fontSize: '20px' })
              }
              title={
                <>
                  <Tooltip title="Baixar arquivo" placement="topLeft">
                    <Typography.Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        outline: 0,
                      }}
                    >
                      <span
                        style={{
                          color: corTema,
                          cursor: 'pointer',
                          display: 'flex',
                          width: '100%',
                          outline: 0,
                        }}
                        onClick={() => fileManagerPaiDownload(item.Link)}
                        role="link"
                        tabIndex="0"
                        aria-hidden="true"
                      >
                        {item.title}
                        <LinkArquivo>- Baixar Arquivo</LinkArquivo>
                      </span>
                    </Typography.Text>
                  </Tooltip>
                </>
              }
            />
          );
        }
        return (
          <TreeNode
            key={item.key}
            isLeaf
            switcherIcon={
              item.Arquivo?.Url ? (
                <Tooltip title="Download" placement="left">
                  <Icons.LinkOutlined style={{ fontSize: '20px', cursor: 'pointer', color: corTema }} onClick={
                    () => fileManagerPaiDownload(item.Arquivo.Url)
                  } />
                </Tooltip>
              ) : (
                <Icons.LinkOutlined style={{ fontSize: '20px' }} />
              )
            }
            title={
              <>
                <Link to={`${visao}/${item.key}`}>
                  <Tooltip title="Acessar" placement="bottomLeft">
                    <Typography.Text
                      style={{
                        display: 'flex',
                        width: '100%',
                        outline: 0,
                      }}
                    >
                      <span
                        style={{
                          color: corTema,
                          cursor: 'pointer',
                          display: 'block',
                          width: '100%',
                          outline: 0,
                        }}
                      >
                        {item.title}
                      </span>
                    </Typography.Text>
                  </Tooltip>
                </Link>
              </>
            }
          />
        );
      });

    const toggleExpandNode = key => {
      setExpandedKeys(prev => {
        const outArr = [...prev];
        if (outArr.includes(key)) {
          return outArr.filter(e => e !== key);
        } else {
          outArr.push(key);
          return outArr;
        }
      });
      setAutoExpandParent(false);
    };

    const onExpand = keys => {
      setExpandedKeys(keys);
      setAutoExpandParent(false);
    };

    useImperativeHandle(ref, () => ({
      onSearch(busca) {
        const searchWithoutAccents = RemoveDiacritic(busca);
        if (searchWithoutAccents) {
          const hasMatchWord = string => {
            const stringWithoutAccents = RemoveDiacritic(string);
            return stringWithoutAccents?.indexOf(searchWithoutAccents) !== -1;
          };
          const filterData = arr =>
            arr?.filter(
              item =>
                hasMatchWord(item.title) || filterData(item.children)?.length
            );
          const filteredData = filterData(treeView).map(n => {
            return {
              ...n,
              children: filterData(n.children),
            };
          });

          const expandedKeys = filteredData?.map(dataItem => dataItem.key);

          setTreeData(filteredData);
          setExpandedKeys(expandedKeys);
          setAutoExpandParent(true);
        } else {
          setSearchValue('');
          setTreeData(treeView);
          setExpandedKeys([]);
          setAutoExpandParent(false);
        }
      },
      onChange(e) {
        setSearchValue(e.target.value);
        if (!e.target.value) {
          setExpandedKeys([]);
          setTreeData(treeView);
        }
      },
    }));

    return (
      <>
        <TreeTransparente
          className="draggable-tree"
          draggable={false}
          defaultExpandAll={false}
          blockNode
          selectable
          height={600}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          titleRender={record => (
            <span
              key={record.key}
              onClick={() => toggleExpandNode(record.key)}
              style={{ display: 'inline-block', width: '100%', height: '100%' }}
            >
              {record.title}
            </span>
          )}
        >
          {loop(treeData)}
        </TreeTransparente>
      </>
    );
  }
);

export default TreeViewModuloFixo;
