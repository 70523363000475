import React, { useState, useContext } from 'react';
import { Form } from 'antd';
import { filtrarGrafico } from '~/modules/ESic/services/esic.service';
import { GraficoContext } from '~/modules/ESic/context/listaGraficoContext';
import Popover from '~/components/Popover/Popover.component';
import Button from '~/components/Button/Button.component';
import Icons from '~/components/Icons/Icons.component';
import { FilterButton } from './style';

function Filter({ idGrafico, contentFilter, exercicio }) {
  const { listaGrafico, setListaGrafico } = useContext(GraficoContext);
  const [visible, setVisible] = useState(false);

  async function onFilter(Id, values) {
    const { Periodo } = values;
    const response = await filtrarGrafico(Id, exercicio, Periodo);

    const index = listaGrafico.findIndex(item => item.id === Id);

    if (index !== -1) {
      const newLista = [...listaGrafico];

      const graficoAtual = newLista[index];

      // Criar um novo componente com os dados atualizados
      // Precisamos preservar a posição e o id, mas atualizar o componente
      const componenteAtualizado = {
        ...graficoAtual,
        component: React.cloneElement(graficoAtual.component, {
          data: response.data.Dados,
          filterDescription: response.data.DescricaoPeriodo,
        }),
      };

      newLista[index] = componenteAtualizado;

      setListaGrafico(newLista);
    }
  }

  const content = (
    <>
      <Form
        name="filter"
        initialValues={{
          remember: true,
          Periodo: contentFilter.Periodicidade,
        }}
        onFinish={values => onFilter(idGrafico, values)}
      >
        {contentFilter.Content}
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => setVisible(false)}
        >
          Filtrar
        </Button>
      </Form>
    </>
  );

  const handleVisibleChange = visibleEvent => setVisible(visibleEvent);

  return (
    <>
      <Popover
        visible={visible}
        onVisibleChange={handleVisibleChange}
        content={content}
        trigger="click"
      >
        <FilterButton>
          <Icons.FilterOutlined
            style={{ position: 'relative', top: 1, left: -7, fontSize: 16 }}
          />
        </FilterButton>
      </Popover>
    </>
  );
}

export default Filter;
