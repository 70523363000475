import api from '~/services/api';
import history from '~/services/history';

function redirectNotFound() {
  history.push('/notfound');
}

function getPeriodicidade() {
  return api.get('periodicidade/periodos');
}

function getConfiguracao() {
  return api.get('esic/configuracoes');
}

function getExercicios() {
  return api.get('esic/exercicios');
}
function getVisoes(exercicio) {
  return api.get(`esic/visoes/exercicio/${exercicio}`);
}

function getGraficos(exercicio) {
  return api.get(`esic/grafico/exercicio/${exercicio}`);
}

function filtrarGrafico(id, exercicio, periodo) {
  return api.get(`esic/grafico/${id}/filtrar/${exercicio}/${periodo}`);
}

export {
  getConfiguracao,
  redirectNotFound,
  getVisoes,
  filtrarGrafico,
  getGraficos,
  getExercicios,
  getPeriodicidade
};
