/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Accordion, Select, Exportacao, Notification } from '~/components';
import { useSelector } from 'react-redux';
import { getUrlComDominio } from '~/modules/Helper/getUrl-helper';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import { Content, ContainerExportar } from './style';
import { UltimaAtualizacao } from '../../Modulos/components/Grid/UltimaAtualizacao.component';
import { exportarVisao } from '../../Modulos/services/moduloVisao.service';

const { Panel } = Accordion;
const { Option } = Select;

function DadosAbertosList({ chaveModulo, visaoAnalitica, modulo }) {
  const periodicidades =
    visaoAnalitica && visaoAnalitica.Periodicidades
      ? visaoAnalitica.Periodicidades
      : [];
  const configuration = useSelector(state => state.configuration);
  const { corTituloGrid } = configuration;
  const dataUltimaAtualizacao = visaoAnalitica.DataUltimaSicronizacaoModulo;

  const [periodicidadeSelecionada, setPeriodicidadeSelecionada] = useState(
    null
  );
  const [periodicidadeDescricao, setPeriodicidadeDescricao] = useState(null);
  const [exercicioSelecionado, setExercicioSelecionado] = useState(null);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(null);
  const [periodoDescricao, setperiodoDescricao] = useState(null);

  const existePeriodicidade = periodicidades.length > 0;
  const periodicidadeUnica = periodicidades.length === 1;

  useEffect(() => {
    if (periodicidadeUnica) {
      setPeriodicidadeSelecionada(periodicidades[0].Id);
      setPeriodicidadeDescricao(periodicidades[0].Descricao);
    }
  }, [visaoAnalitica]);

  const handlePeriodicidadeChange = value => {
    const parsedValue = JSON.parse(value);
    const { periodicidadeId, periodicidadeDescricao } = parsedValue;

    setPeriodicidadeSelecionada(periodicidadeId);
    setPeriodicidadeDescricao(periodicidadeDescricao);
    setExercicioSelecionado(null);
    setperiodoDescricao(null);
  };

  const handleExercicioChange = value => {
    const parsedValue = JSON.parse(value);
    const { exercicio } = parsedValue;

    setExercicioSelecionado(exercicio);
  };

  const handlePeriodoChange = value => {
    const parsedValue = JSON.parse(value);
    const { periodoId, periodoDescricao } = parsedValue;

    setPeriodoSelecionado(periodoId);
    setperiodoDescricao(periodoDescricao);
  };

  async function exportar(tipoExportacao) {
    const payload = {
      ChaveModulo: chaveModulo,
      Exercicio: existePeriodicidade ? Number(exercicioSelecionado) : null,
      Periodicidade: periodicidadeSelecionada,
      Periodo: periodoSelecionado,
      NomeVisao: visaoAnalitica.NomeVisaoAnalitica,
      UrlExportacao: getUrlComDominio(),
    };

    if (
      !payload.Periodicidade ||
      (existePeriodicidade &&
        periodicidadeSelecionada !== 'ANUAL' &&
        !payload.Periodo) ||
      (existePeriodicidade && !payload.Exercicio)
    ) {
      Notification.showError('Selecione os filtros antes de exportar.');
      return;
    }

    try {
      const res = await exportarVisao(tipoExportacao, payload);
      fileManagerPaiDownload(res.data);
    } catch (error) {
      Notification.showError(error.response.data);
    }
  }

  return (
    <>
      <Content>
        <Panel
          forceRender
          header={modulo.Nome}
          key="1"
          style={{ marginBottom: '14px' }}
        >
          <Content dangerouslySetInnerHTML={{ __html: visaoAnalitica }} />
        </Panel>

        <Select
          disabled={!existePeriodicidade || periodicidadeUnica}
          style={{ width: 240, marginBottom: 10 }}
          onChange={handlePeriodicidadeChange}
          placeholder="Periodicidade"
          value={periodicidadeDescricao}
        >
          {periodicidades &&
            periodicidades.map(periodicidade => (
              <Option
                key={periodicidade.Id}
                value={JSON.stringify({
                  periodicidadeId: periodicidade.Id,
                  periodicidadeDescricao: periodicidade.Descricao,
                })}
              >
                {periodicidade.Descricao}
              </Option>
            ))}
        </Select>

        {existePeriodicidade && periodicidadeSelecionada !== 'ANUAL' && (
          <>
            <Select
              style={{ width: 120, marginBottom: 10, marginLeft: 10 }}
              onChange={handlePeriodoChange}
              placeholder="Período"
              disabled={!periodicidadeSelecionada}
              value={periodoDescricao}
            >
              {periodicidades
                .filter(p => p.Id === periodicidadeSelecionada)
                .map(periodicidade =>
                  periodicidade.Periodos?.map(periodo => (
                    <Option
                      key={periodo.Id}
                      value={JSON.stringify({
                        periodoId: periodo.Id,
                        periodoDescricao: periodo.Descricao,
                      })}
                    >
                      {periodo.Descricao}
                    </Option>
                  ))
                )}
            </Select>
          </>
        )}

        <Select
          style={{ width: 120, marginBottom: 10, marginLeft: 10 }}
          onChange={handleExercicioChange}
          disabled={!periodicidadeSelecionada}
          placeholder="Exercício"
          value={exercicioSelecionado}
        >
          {visaoAnalitica?.Exercicios?.map(exercicio => (
            <Option
              key={exercicio.Exercicio}
              value={JSON.stringify({ exercicio: exercicio.Exercicio })}
            >
              {exercicio.Exercicio}
            </Option>
          ))}
        </Select>
        <ContainerExportar>
          <Exportacao onClick={exportar} semURI />
        </ContainerExportar>
        <div style={{ marginTop: '10px' }}>
          {dataUltimaAtualizacao && (
            <UltimaAtualizacao
              dataHora={dataUltimaAtualizacao}
              corTituloGrid={corTituloGrid}
            />
          )}
        </div>
      </Content>
    </>
  );
}

export default DadosAbertosList;
