import React, { useEffect, useState } from 'react';
import { Pie } from '@antv/g2plot';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Icons, Typography } from '~/components';
import { useWindowListener, tiposDevice } from './windowListener.component';
import {
  GraficoDonut,
  Visualizar,
  CardWrapper,
  NoDataContainer,
} from './style';
import Filter from './Filter.component';
import ExercicioSelect from './ExercicioSelect.component';

function DonutGraphic({
  id,
  titulo,
  data,
  legenda,
  tootltipTitle,
  ValorLink,
  hasFilter,
  filterDescription,
  contentFilter,
  showEyeButton = false,
  corFonte,
  showExercicioSelect,
  exercicio,
}) {
  const [donut, setDonut] = useState(null);
  const windowListener = useWindowListener();
  const {
    graficHighContrastStyle,
    highContrast,
    graficDefaultStyle,
    zoomFonte,
  } = useSelector(state => state.configuration);
  const getConfigStyle = highContrast
    ? graficHighContrastStyle
    : graficDefaultStyle;
  const legend = {
    visible: true,
    position: 'left',
    maxItemWidth: 160,
    title: {
      visible: true,
      text: legenda,
      style: getConfigStyle,
    },
    itemName: {
      formatter: v => {
        return v.trim().length >= 27 ? `${v.substring(0, 27)}...` : v.trim();
      },
      style: getConfigStyle,
    },
  };
  const [config] = useState({
    title: false,
    legend,
    autoFit: true,
    radius: 0.8,
    innerRadius: 0.65,
    label: {
      autoHide: true,
      type: 'spider',
      formatter: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: getConfigStyle,
    },
    statistic: {
      title: false,
      content: false,
    },
    colorField: 'EixoX',
    angleField: 'EixoY',
    color: [
      '#30BBAA',
      '#F39366',
      '#876AB0',
      '#FDF25B',
      '#77C966',
      '#EE7B80',
      '#7F8BB2',
      '#F9D04F',
      '#D85B81',
      '#D3E045',
      '#2DA0D7',
      '#FBB141',
    ],
    tooltip: {
      showTitle: true,
      title: tootltipTitle,
      formatter: ({ EixoX, EixoY }) => {
        return {
          name: EixoX,
          value: new Intl.NumberFormat('pt-BR').format(EixoY),
        };
      },
    },
  });

  useEffect(() => {
    if (donut) return;

    const element = document.getElementById(id);
    if (element) {
      const donutRender = new Pie(element, { data, ...config });
      setDonut(donutRender);
      donutRender.render();
    }
  }, [config, data, donut, id]);

  useEffect(() => {
    if (windowListener && windowListener.device && donut) {
      const visible = windowListener.device === tiposDevice.desktop;
      donut.update({
        legend: visible ? legend : false,
        label: {
          ...config.label,
          style: getConfigStyle,
        },
      });
    }

    if (donut) donut.render();
  }, [
    donut,
    windowListener,
    highContrast,
    config.text,
    config.label,
    getConfigStyle,
    zoomFonte,
  ]);

  useEffect(() => {
    if (donut) {
      donut.update({
        data,
      });
      donut.render();
    }
  }, [data, donut]);

  const hasNoData = !data || data.length === 0;

  return (
    <>
      <CardWrapper
        title={
          <span
            style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
          >
            {titulo}
          </span>
        }
        extra={
          <>
            {showExercicioSelect && <ExercicioSelect id={id} />}
            {showEyeButton && (
              <Visualizar>
                <Link to={ValorLink} style={{ color: corFonte }}>
                  <Icons.EyeOutlined />
                </Link>
              </Visualizar>
            )}
          </>
        }
        size="small"
        style={{ height: '100%' }}
      >
        <Row>
          {hasFilter && (
            <Col flex="45px" style={{ display: 'flex' }}>
              <Filter
                idGrafico={id}
                contentFilter={contentFilter}
                exercicio={exercicio}
              />
            </Col>
          )}
          {filterDescription && (
            <Col flex="auto">
              <Typography.Text
                style={{
                  position: 'relative',
                  top: -6,
                }}
              >
                {filterDescription}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Row style={{ flex: 1, minHeight: 'inherit' }}>
          {hasNoData ? (
            <NoDataContainer>
              <Typography.Text>
                Não há dados disponíveis para exibição
              </Typography.Text>
            </NoDataContainer>
          ) : (
            <GraficoDonut id={id} />
          )}
        </Row>
      </CardWrapper>
    </>
  );
}

export default DonutGraphic;
