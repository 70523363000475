import styled, { createGlobalStyle, css } from 'styled-components';
import { Menu as AntDesignMenu } from 'antd';
import { Input } from '../Input/Input.styled';

const { Search } = Input;

export const Menu = styled(AntDesignMenu)`
  height: calc(100svh - 156px);
  overflow-y: auto;
  overflow-x: hidden;

  // Adicionando padding no último item do menu
  .ant-menu-item:last-child,
  .ant-menu-submenu:last-child {
    margin-bottom: 24px !important;
    padding-bottom: 24px !important;
  }
`;

const menuTitulo = css`
  font-size: ${({ theme }) => `${14 + 2 * theme.zoomFonte}px`};
  display: inline-block;
  word-wrap: break-word;
  white-space: break-spaces;
  line-height: 1.6;
`;

export const SubMenu = styled(AntDesignMenu.SubMenu)`
  .menu-titulo {
    ${menuTitulo}
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-submenu-title {
    padding: 0px 20px;
  }

  .anticon-arrow-right {
    border: 1px solid;
    border-radius: 50%;
    padding: 3px;

    svg {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
`;

export const MenuItem = styled(AntDesignMenu.Item)`
  .menu-titulo {
    ${menuTitulo}
  }
`;

export const SearchMenu = styled(Search)`
  margin: 16px;
  display: flex;
  width: auto;
`;

export const MenuStyles = createGlobalStyle`

  .ant-menu-title-content, .ant-menu-item-active {
    color: #fff !important;
    text-decoration-color: none !important;
    text-emphasis-color: none !important;
  }

  .ant-menu-item:hover, .ant-menu-submenu-open {
        text-decoration: underline;
  }

  .ant-menu-dark .ant-menu-inline. ant-menu-sub {
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
  }

  .ant-layout-sider-children {
    position: fixed;
    width:inherit;
  }

  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title{
    line-height:normal;
    height:auto;
    margin: 12px 0px;
    text-wrap: auto;
  }

  .ant-menu-inline-collapsed-tooltip { 
    display: none;
  }

  .ant-tooltip { 
    opacity: 0.8; 
  }
`;
