import React, { useEffect, useState } from 'react';
import { Bar } from '@antv/g2plot';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Icons, Typography } from '~/components';
import { GraficoBar, Visualizar, CardWrapper, NoDataContainer } from './style';
import Filter from './Filter.component';
import ExercicioSelect from './ExercicioSelect.component';

function BarGraphic({
  id,
  titulo,
  data,
  cor,
  legendaEixoX,
  legendaEixoY,
  ValorLink,
  hasFilter,
  filterDescription,
  contentFilter,
  showEyeButton = false,
  corFonte,
  showExercicioSelect,
  exercicio,
}) {
  const [bar, setBar] = useState(null);
  const {
    graficHighContrastStyle,
    highContrast,
    graficDefaultStyle,
    zoomFonte,
  } = useSelector(state => state.configuration);
  const getConfigStyle = highContrast
    ? graficHighContrastStyle
    : graficDefaultStyle;

  const NumberFormatter = function NumberFormatter(value) {
    let newNum = value;
    const suffix = ['', ' Mil', ' Mi', ' Bi', ' Tri'];
    let suffixNum = 0;
    if (newNum > 10000) {
      while (newNum >= 1000) {
        newNum /= 1000;
        suffixNum += 1;
      }
      return Math.trunc(newNum) + suffix[suffixNum];
    }
    return newNum;
  };

  const [config] = useState({
    padding: [15, 20, 55, 70],
    title: false,
    autoFit: true,
    data,
    xField: 'EixoY',
    yField: 'EixoX',
    color: cor,
    xAxis: {
      title: {
        visible: legendaEixoX || false,
        text: legendaEixoX || '',
        style: getConfigStyle,
      },
      label: {
        formatter: v => {
          return NumberFormatter(v);
        },
        style: getConfigStyle,
      },
    },
    yAxis: {
      title: {
        visible: false,
        text: '',
        style: getConfigStyle,
      },
      label: {
        formatter: v => {
          return v.length >= 7 ? `${v.substring(0, 7)}...` : v;
        },
        style: getConfigStyle,
      },
    },
    label: {
      visible: true,
      formatter: ({ EixoY }) => {
        return NumberFormatter(EixoY);
      },
      position: 'right',
      style: getConfigStyle,
    },
    tooltip: {
      showTitle: true,
      title: legendaEixoY,
      formatter: ({ EixoX, EixoY }) => {
        return {
          name: EixoX,
          value: new Intl.NumberFormat('pt-BR').format(EixoY),
        };
      },
    },
  });

  useEffect(() => {
    if (bar) return;

    const element = document.getElementById(id);
    if (element) {
      const barRender = new Bar(element, { data, ...config });
      setBar(barRender);
      barRender.render();
    }
  }, [bar, config, data, id]);

  useEffect(() => {
    if (bar) {
      bar.update({
        label: {
          ...config.label,
          style: getConfigStyle,
        },
        xAxis: {
          ...config.xAxis,
          title: {
            ...config.xAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.xAxis.label,
            style: getConfigStyle,
          },
        },
        yAxis: {
          ...config.yAxis,
          title: {
            ...config.yAxis.title,
            style: getConfigStyle,
          },
          label: {
            ...config.yAxis.label,
            style: getConfigStyle,
          },
        },
      });
    }

    if (bar) bar.render();
  }, [
    bar,
    config.label,
    config.xAxis,
    config.yAxis,
    getConfigStyle,
    highContrast,
    zoomFonte,
  ]);

  useEffect(() => {
    if (bar) {
      bar.update({
        data,
      });
      bar.render();
    }
  }, [data, bar]);

  const hasNoData = !data || data.length === 0;

  return (
    <>
      <CardWrapper
        title={
          <span
            style={{ fontSize: `${18 + 2 * zoomFonte}px`, fontWeight: 500 }}
          >
            {titulo}
          </span>
        }
        extra={
          <>
            {showExercicioSelect && <ExercicioSelect id={id} />}
            {showEyeButton && (
              <Visualizar>
                <Link to={ValorLink} style={{ color: corFonte }}>
                  <Icons.EyeOutlined />
                </Link>
              </Visualizar>
            )}
          </>
        }
        size="small"
      >
        <Row>
          {hasFilter && (
            <Col flex="45px" style={{ display: 'flex' }}>
              <Filter
                idGrafico={id}
                contentFilter={contentFilter}
                exercicio={exercicio}
              />
            </Col>
          )}
          {filterDescription && (
            <Col flex="auto">
              <Typography.Text
                style={{
                  position: 'relative',
                  top: -6,
                }}
              >
                {filterDescription}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Row style={{ flex: 1, minHeight: 'inherit' }}>
          {hasNoData ? (
            <NoDataContainer>
              <Typography.Text>
                Não há dados disponíveis para exibição
              </Typography.Text>
            </NoDataContainer>
          ) : (
            <GraficoBar id={id} />
          )}
        </Row>
      </CardWrapper>
    </>
  );
}

export default BarGraphic;
