/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { iconEnum } from '~/modules/Helper/iconEnum';
import { TIPO_MENU_PORTAL } from '~/modules/Helper/tipoMenuPortal';
import { useMenus } from '~/contexts/menu.context';
import { useSelector } from 'react-redux';
import { getUrlDominio } from '~/modules/Helper/getUrl-helper';

import * as S from './Menu.styled';
import Tooltip from '../Tooltip/Tooltip.component';
import Icons from '../Icons/Icons.component';

function Menu({ openKeys, setOpenKeys, setCollapsed, collapsed }) {
  const { menus, setMenuSelecionado } = useMenus();
  const { highContrast } = useSelector(state => state.configuration);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const history = useHistory();

  function handleTitleClick(name, idMenuItem) {

    // Função auxiliar para encontrar o item e seus níveis
    const findItemAndLevels = (items, targetId, levels = []) => {
      for (const item of items) {
        if (item.Id === targetId) {
          return [...levels, item.Titulo];
        }

        if (item.SubItens && item.SubItens.length > 0) {
          const found = findItemAndLevels(item.SubItens, targetId, [
            ...levels,
            item.Titulo,
          ]);
          if (found) return found;
        }
      }
      return null;
    };

    // Encontra todos os níveis até o item clicado
    const menuLevels = findItemAndLevels(menus, idMenuItem);

    if (menuLevels) {
      // Se encontrou o item, atualiza openKeys
      if (openKeys.includes(name)) {
        // Se o item já está aberto, mantém apenas os níveis até ele
        setOpenKeys(openKeys.filter(key => menuLevels.includes(key)));
      } else {
        // Se o item está fechado, mantém apenas os níveis necessários
        const newOpenKeys = openKeys.filter(key => menuLevels.includes(key));
        setOpenKeys([...newOpenKeys, name]);
      }
    } else {
      // 1º nivel é aqui
      setOpenKeys([name]);
    }
  }

  function formatUrl(url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  }

  function handleRedirect(tipoMenuPortal, uri, subItens) {
    if (tipoMenuPortal === TIPO_MENU_PORTAL.EXTERNO) {
      // externo
      let newUri = '';
      if (subItens !== null) newUri = formatUrl(uri);
      else newUri = getUrlDominio() + uri;
      window.open(newUri);
    } else {
      history.replace(`/`);
      history.push(uri);
    }
    setOpenKeys([]);
  }

  function tratarTextoBusca(texto) {
    return texto
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function handleSearchChange(event) {
    const { value } = event.target;
    setSearchValue(value);

    if (value === '') {
      setSearchResult([]);
      setOpenKeys([]);
      return;
    }

    const searchTerm = tratarTextoBusca(value);

    const findMatchingSubItems = (subItems, searchTerm) => {
      let matchedList = [];

      subItems.forEach(subItem => {
        const subItemTitle = tratarTextoBusca(subItem.Titulo);

        if (subItemTitle.includes(searchTerm)) {
          matchedList.push(subItem);
        }

        if (subItem.SubItens && subItem.SubItens.length > 0) {
          matchedList = matchedList.concat(
            findMatchingSubItems(subItem.SubItens, searchTerm)
          );
        }
      });

      return matchedList;
    };

    const results = menus.reduce((acc, menu) => {
      const {
        Id,
        Titulo,
        TipoMenuPortal,
        SubItens,
        Icone,
        URI,
        ChaveModulo,
      } = menu;

      const menuTitle = tratarTextoBusca(Titulo);
      let matchFound = menuTitle.includes(searchTerm);
      let matchedList = [];

      if (SubItens && SubItens.length > 0) {
        matchedList = findMatchingSubItems(SubItens, searchTerm);
        if (matchedList.length > 0) {
          matchFound = true;
        }
      } else if (menuTitle.includes(searchTerm)) {
        matchFound = true;
        matchedList.push(menu);
      }

      if (matchFound) {
        if (matchedList.length > 0) {
          matchedList.forEach(matchedItem => acc.push(matchedItem));
        } else {
          acc.push({
            Id,
            Titulo,
            TipoMenuPortal,
            SubItens,
            Icone,
            URI,
            ChaveModulo,
          });
        }
      }

      return acc;
    }, []);

    setSearchResult(results);
  }

  function renderMenuItem(menu) {
    const { Titulo, TipoMenuPortal, SubItens, Icone, URI, IdMenuItem } = menu;

    const handleSubMenuTitleClick = () => {
      if (URI) {
        handleRedirect(TipoMenuPortal, URI, SubItens);
        setMenuSelecionado(menu);
      }
    };

    const handleSubMenuTitleClick2 = () => {
      if (URI) {
        handleRedirect(TipoMenuPortal, URI, SubItens);
        setMenuSelecionado(menu);
      }
    };

    const handleMenuClick = () => {
      handleRedirect(TipoMenuPortal, URI, SubItens);
      setMenuSelecionado(menu);
      if (setCollapsed) setCollapsed(false);
    };

    const handleSubMenuIconClick = () => {
      handleTitleClick(Titulo, IdMenuItem);
    };

    const TitleMenu = () => <span>{Titulo}</span>;

    if (SubItens.length > 0) {
      return (
        <S.SubMenu
          key={Titulo}
          tabIndex={0}
          title={
            <Tooltip
              title={`⌂ / ${menu.Breadcrumb}`}
              placement="top"
              overlayClassName={
                !collapsed ? 'ant-menu-inline-collapsed-tooltip' : ''
              }
            >
              <div
                onMouseEnter={handleSubMenuIconClick}
                onClick={handleSubMenuTitleClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 12,
                  cursor: `${!menu.URI && 'default'}`,
                }}
              >
                {Icone && iconEnum.find(x => x.name === Icone)?.value()}
                <span style={{ flexGrow: 1, margin: 0 }}>
                  <TitleMenu />
                </span>
                <div onClick={handleSubMenuIconClick}>
                  <Icons.ArrowRightOutlined />
                </div>
              </div>
            </Tooltip>
          }
        >
          {SubItens.map(subItem => renderMenuItem({ Titulo, ...subItem }))}
        </S.SubMenu>
      );
    }
    return (
      <Tooltip
        title={`⌂ / ${menu.Breadcrumb}`}
        placement="top"
        overlayClassName={!collapsed ? 'ant-menu-inline-collapsed-tooltip' : ''}
      >
        <S.MenuItem
          key={Titulo}
          onMouseEnter={handleSubMenuIconClick}
          onClick={handleSubMenuTitleClick2}
          tabIndex={0}
          icon={Icone && iconEnum.find(x => x.name === Icone)?.value()}
          style={!collapsed ? { padding: '0px 20px' } : {}}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleMenuClick();
            }
          }}
        >
          <TitleMenu />
        </S.MenuItem>
      </Tooltip>
    );
  }

  const handleMouseLeave = () => {
    setOpenKeys([]); // Fecha todos os submenus
  };

  return (
    <>
      <S.MenuStyles />
      {!collapsed ? (
        <Tooltip title="Pesquisar itens do menu">
          <S.SearchMenu
            placeholder="Pesquisar"
            maxLength={50}
            value={searchValue}
            type="text"
            onChange={handleSearchChange}
          />
        </Tooltip>
      ) : null}
      <S.Menu
        theme={!highContrast ? 'dark' : 'light'}
        mode="vertical"
        inlineIndent={16}
        forceSubMenuRender
        openKeys={openKeys}
        onMouseLeave={handleMouseLeave}
      >
        {menus && searchResult.length > 0
          ? searchResult.map(renderMenuItem)
          : menus.map(renderMenuItem)}
      </S.Menu>
    </>
  );
}

export default withRouter(Menu);
