/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import DonutGraphic from '~/components/Grafico/DonutGraphic.component';
import PieGraphic from '~/components/Grafico/PieGraphic.component';
import BarGraphic from '~/components/Grafico/BarGraphic.component';
import ColumnGraphic from '~/components/Grafico/ColumnGraphic.component';

import {
  periodicidadeBimestral,
  periodicidadeAnual,
  periodicidadeMensal,
  periodicidadeSemestral,
} from '~/modules/Helper/FiltrosPeriodicidade';

import {
  Informacao,
  PageTitle,
  ImageButton,
  Row,
  ButtonGoBack,
} from '~/components';
import FiltroExercicio from '../components/FiltroExercicio.component';
import {
  ListaGraficoContext,
  GraficoContext,
} from '../context/listaGraficoContext';

import {
  redirectNotFound,
  getConfiguracao,
  getVisoes,
  getGraficos,
  getExercicios,
  getPeriodicidade,
} from '../services/esic.service';

import ESicVisoes from '../components/visoes.component';

function ESic() {
  const showEyeButton = false;

  const history = useHistory();
  const [visoesESic, setVisoesESic] = useState(null);
  const [formularioESIC, setFormularioESIC] = useState(null);
  const [periodicidade, setPeriodicidade] = useState(null);
  const [exercicioSelcionado, setExercicioSelecionado] = useState(null);
  const [exercicios, setExercicios] = useState([]);

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const { eSic, setESic, listaGrafico, setListaGrafico } = useContext(
    GraficoContext
  );

  // useMemo
  const gridContainer = useMemo(
    () => ({
      overflow: 'hidden',
      marginLeft: '-10px',
    }),
    []
  );

  const gridBackground = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      left: '0px',
      right: '0px',
      backgroundSize: '3% 3%',
    }),
    []
  );

  const handleChangeExercicio = useCallback(anoSelecionado => {
    setExercicioSelecionado(anoSelecionado);
  }, []);

  function obterUrlFormulario({
    UrlArquivoPessoaFisicaReclamacao,
    UrlArquivoPessoaFisicaRecurso,
    UrlArquivoPessoaFisicaeSIC,
    UrlArquivoPessoaJuridicaReclamacao,
    UrlArquivoPessoaJuridicaRecurso,
    UrlArquivoPessoaJuridicaeSIC,
  }) {
    const data = [
      {
        Entidade: 'e-SIC',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaeSIC,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaeSIC,
          },
        ],
      },
      {
        Entidade: 'Reclamações',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaReclamacao,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaReclamacao,
          },
        ],
      },
      {
        Entidade: 'Recursos',
        Fomulario: [
          {
            Descricao: 'Arquivo Pessoa Física',
            Url: UrlArquivoPessoaFisicaRecurso,
          },
          {
            Descricao: 'Arquivo Pessoa Jurídica',
            Url: UrlArquivoPessoaJuridicaRecurso,
          },
        ],
      },
    ];

    setFormularioESIC(data);
  }

  function getFilterContent(exibicao) {
    switch (exibicao) {
      case 1:
        return {
          Periodicidade: periodicidade[0]?.Periodos[0]?.Id,
          Content: periodicidadeMensal(periodicidade[0]?.Periodos),
        };
      case 2:
        return {
          Periodicidade: periodicidade[1]?.Periodos[0]?.Id,
          Content: periodicidadeBimestral(periodicidade[1]?.Periodos),
        };
      case 5:
        return {
          Periodicidade: periodicidade[4]?.Periodos[0]?.Id,
          Content: periodicidadeSemestral(periodicidade[4]?.Periodos),
        };
      case 6:
        return {
          Periodicidade: 2021,
          Content: periodicidadeAnual,
        };
      default:
        return null;
    }
  }

  function atualizarGraficos(listaGraficoParam) {
    if (!periodicidade || !listaGraficoParam?.length) return;

    const criarConfigBase = graficoESic => {
      const filterContent = getFilterContent(graficoESic.Exibicao);
      if (!filterContent) return null;

      return {
        id: graficoESic.Id,
        titulo: graficoESic.Titulo,
        data: graficoESic.Dados,
        height: '370px',
        filterDescription: graficoESic.DescricaoPeriodo,
        hasFilter: true,
        contentFilter: filterContent,
        showEyeButton,
        exercicio: exercicioSelcionado,
        legenda: graficoESic.LegendaCampoValor,
        key: graficoESic.Id,
      };
    };

    // Mapa de componentes para simplificar o switch
    const componentesGrafico = {
      1: config => <PieGraphic {...config} />,
      2: config => <ColumnGraphic {...config} />,
      3: config => <BarGraphic {...config} />,
      4: config => <DonutGraphic {...config} />,
    };

    const listaGraficosESic = listaGraficoParam
      .map(graficoESic => {
        //
        const configGrafico = criarConfigBase(graficoESic);
        const criarComponente = componentesGrafico[graficoESic.TipoGrafico];
        if (!criarComponente) return null;
        return {
          id: graficoESic.Id,
          position: graficoESic.Posicao,
          component: criarComponente(configGrafico),
        };
      })
      .filter(Boolean);

    setListaGrafico(listaGraficosESic);
  }

  const createElement = useCallback(el => {
    return (
      <div
        key={el.id}
        data-grid={{ ...el.position, isDraggable: false, static: true }}
      >
        {el.component}
      </div>
    );
  }, []);

  const goBack = useCallback(() => {
    history.push('/');
  }, [history]);

  useEffect(() => {
    async function loadInitialData() {
      const [
        exerciciosResponse,
        eSicResponse,
        periodicidadeResponse,
      ] = await Promise.all([
        getExercicios(),
        getConfiguracao(),
        getPeriodicidade(),
      ]);

      // Verifica e atualiza periodicidade primeiro
      if (periodicidadeResponse?.data) {
        setPeriodicidade(periodicidadeResponse.data);
      }

      // Verifica e atualiza eSic
      if (eSicResponse?.data) {
        if (!eSicResponse.data.Habilitado) {
          redirectNotFound();
          return;
        }
        setESic(eSicResponse.data);
        obterUrlFormulario(eSicResponse.data);
      }

      // Verifica e atualiza exercícios e seta o maior exercício
      if (exerciciosResponse?.data) {
        setExercicios(exerciciosResponse.data);
        // const maiorExercicio = Math.max(...exerciciosResponse.data);
        setExercicioSelecionado(0); // Zero representa 'Todos'
      }
    }

    loadInitialData();
  }, []);

  useEffect(() => {
    async function loadVisoesEGraficos() {
      //
      const [visoesResponse, graficosResponse] = await Promise.all([
        getVisoes(exercicioSelcionado),
        getGraficos(exercicioSelcionado),
      ]);

      if (visoesResponse?.data) {
        setVisoesESic(visoesResponse.data);
      }

      if (graficosResponse?.data) {
        atualizarGraficos(graficosResponse.data);
      }
    }

    if (!exercicioSelcionado) setExercicioSelecionado(0);
    if (periodicidade) loadVisoesEGraficos();
  }, [exercicioSelcionado, periodicidade]);

  return (
    <>
      {eSic && (
        <>
          <PageTitle titulo={eSic.TituloPagina} />
          <ImageButton
            type="default"
            shape="round"
            imagem={eSic.Icone}
            uri={eSic.UrlAplicacao || null}
            tooltip="Acesse o Portal do e-SIC"
          />

          {eSic?.Informacao?.Texto && (
            <Informacao
              titulo={eSic.Informacao.Titulo}
              informacao={eSic.Informacao.Texto}
            />
          )}

          <FiltroExercicio
            handleChangeExercicio={handleChangeExercicio}
            exercicios={exercicios}
            exercicio={exercicioSelcionado}
          />

          {visoesESic != null && formularioESIC != null && (
            <ESicVisoes dados={visoesESic} formularios={formularioESIC} />
          )}
        </>
      )}

      {listaGrafico?.length > 0 && listaGrafico.every(item => item?.component) && (
        <div style={gridContainer}>
          <div style={gridBackground} />
          <ResponsiveReactGridLayout
            breakpoints={{ lg: 1440, md: 1024, sm: 768 }}
            cols={{ lg: 12, md: 12, sm: 1 }}
          >
            {_.map(listaGrafico, el => createElement(el))}
          </ResponsiveReactGridLayout>
        </div>
      )}

      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

const ESicMemoized = memo(ESic);

function Container() {
  return (
    <ListaGraficoContext>
      <ESicMemoized />
    </ListaGraficoContext>
  );
}

export default Container;
